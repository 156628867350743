<template>
  <div>
    <div>
      <b-row style="border-bottom: 3px solid black">
        <b-col>
          <img
            src="@/assets/bradesco.png"
            class="thumbnail p-0 m-0"
            width="120px"
            alt=""
          />
        </b-col>
        <b-col
          style="border-right: 3px solid black; border-left: 3px solid black"
          ><span class="strong lead">{{ unityData.bank_number }}</span></b-col
        >
        <b-col md="8" class="d-flex align-items-center justify-content-end p-0"
          ><span>{{ digitableLine }}</span>
        </b-col>
      </b-row>
      <b-row
        style="
          border-left: 3px solid black;
          border-right: 3px solid black;
          border-bottom: 3px solid black;
        "
      >
        <b-col md="9">
          <b-row style="border-bottom: 1px solid black">
            <b-col class="d-flex flex-column align-items-start boleto-cel">
              <span class="boleto-cel-title">Data do documento</span>
              <span class="boleto-cel-value">{{ documentDate }}</span>
            </b-col>
            <b-col
              class="d-flex flex-column align-items-start boleto-cel"
              style="border-left: 1px solid black"
            >
              <span class="boleto-cel-title">Número do documento</span>
              <span class="boleto-cel-value">{{
                data.invoice[0].number_document
              }}</span>
            </b-col>
            <b-col
              class="d-flex flex-column align-items-start boleto-cel"
              style="border-left: 1px solid black"
            >
              <span class="boleto-cel-title">Nosso Número</span>
              <span class="boleto-cel-value">{{ data.invoice[0].number }}</span>
            </b-col>
            <b-col
              class="d-flex flex-column align-items-start boleto-cel"
              style="border-left: 1px solid black"
            >
              <span class="boleto-cel-title"
                >Agência/Código do Beneficiário</span
              >
              <span class="boleto-cel-value"
                >{{ unityData.agency
                }}<template v-if="unityData.agency_d"
                  >-{{ unityData.agency_d }}</template
                ></span
              >
            </b-col>
          </b-row>
          <b-row style="border-bottom: 1px solid black">
            <b-col class="d-flex flex-column align-items-start boleto-cel">
              <span class="boleto-cel-title">Local de Pagamento</span>
              <span class="boleto-cel-value"
                >PAGÁVEL EM QUALQUER BANCO, LOTÉRICA, INTERNET</span
              >
            </b-col>
          </b-row>
          <b-row style="border-bottom: 1px solid black">
            <b-col class="d-flex flex-column align-items-start boleto-cel">
              <span class="boleto-cel-title" style="font-weight: 900"
                >Beneficiário</span
              >
              <span class="boleto-cel-value" style="font-weight: 900">{{
                data.unityBankAccount[0].beneficiary_name
              }}</span>
            </b-col>
          </b-row>
          <b-row style="border-bottom: 1px solid black">
            <b-col class="d-flex flex-column align-items-start boleto-cel">
              <span class="boleto-cel-title">Intermediado por:</span>
              <span class="boleto-cel-value">{{ unityData.company_name }}</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="d-flex flex-column align-items-start boleto-cel">
              <span class="boleto-cel-title">Pagador:</span>
              <span class="boleto-cel-value">{{
                data.financialClient.financial_name
              }}</span>
              <span class="boleto-cel-value"
                >{{ address.address }}, {{ address.number }} -
                {{ address.district }} - {{ address.city }} -
                {{ address.state }} - CEP: {{ address.zip_code }}</span
              >
              <span class="boleto-cel-title">Sacador/Avalista</span>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="3" style="border-left: 1px solid black">
          <b-row style="border-bottom: 1px solid black">
            <b-col class="d-flex flex-column align-items-start boleto-cel">
              <span class="boleto-cel-title">Vencimento</span>
              <span class="boleto-cel-value">{{ vencimentDay }}</span>
            </b-col>
          </b-row>
          <b-row style="border-bottom: 1px solid black">
            <b-col class="d-flex flex-column align-items-start boleto-cel">
              <span class="boleto-cel-title">(=)Valor do documento</span>
              <span class="boleto-cel-value" style="font-weight: 800">{{
                toMonetary(data.invoice[0].total)
              }}</span>
            </b-col>
          </b-row>
          <b-row style="border-bottom: 1px solid black">
            <b-col class="d-flex flex-column align-items-start boleto-cel">
              <span class="boleto-cel-title">(-)Desconto/Abatimento</span>
              <span class="boleto-cel-value" style="font-weight: 800"
                >&nbsp;</span
              >
            </b-col>
          </b-row>
          <b-row style="border-bottom: 1px solid black">
            <b-col class="d-flex flex-column align-items-start boleto-cel">
              <span class="boleto-cel-title">(+)Mora/Multa/Juros</span>
              <span class="boleto-cel-value" style="font-weight: 800"
                >&nbsp;</span
              >
            </b-col>
          </b-row>
          <b-row style="border-bottom: 1px solid black">
            <b-col class="d-flex flex-column align-items-start boleto-cel">
              <span class="boleto-cel-title">(+)Outros Acréscimos</span>
              <span class="boleto-cel-value" style="font-weight: 800"
                >&nbsp;</span
              >
            </b-col>
          </b-row>
          <b-row>
            <b-col class="d-flex flex-column align-items-start boleto-cel">
              <span class="boleto-cel-title">(=)Valor cobrado</span>
              <span class="boleto-cel-value" style="font-weight: 800"
                >&nbsp;</span
              >
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="d-flex flex-column align-items-end p-0">
          <span style="font-size: 12px; font-weight: 900"
            >RECIBO DO PAGADOR</span
          >
          <span style="font-size: 10px; font-weight: 700"
            >Autenticação Mecânica</span
          >
        </b-col>
      </b-row>
    </div>
    <hr class="dashed" />
    <div>
      <b-row style="border-bottom: 3px solid black">
        <b-col>
          <img
            src="@/assets/bradesco.png"
            class="thumbnail p-0 m-0"
            width="120px"
            alt=""
          />
        </b-col>
        <b-col
          style="border-right: 3px solid black; border-left: 3px solid black"
          ><span class="strong lead">{{ unityData.bank_number }}</span></b-col
        >
        <b-col md="8" class="d-flex align-items-center justify-content-end p-0"
          ><span>{{ digitableLine }}</span></b-col
        >
      </b-row>
      <b-row
        style="
          border-left: 3px solid black;
          border-right: 3px solid black;
          border-bottom: 3px solid black;
        "
      >
        <b-col md="9">
          <b-row style="border-bottom: 1px solid black">
            <b-col class="d-flex flex-column align-items-start boleto-cel">
              <span class="boleto-cel-title">Data do documento</span>
              <span class="boleto-cel-value">{{ documentDate }}</span>
            </b-col>
            <b-col
              class="d-flex flex-column align-items-start boleto-cel"
              style="border-left: 1px solid black"
            >
              <span class="boleto-cel-title">Número do documento</span>
              <span class="boleto-cel-value">{{
                data.invoice[0].number_document
              }}</span>
            </b-col>
            <b-col
              class="d-flex flex-column align-items-start boleto-cel"
              style="border-left: 1px solid black"
            >
              <span class="boleto-cel-title">Nosso Número</span>
              <span class="boleto-cel-value">{{ data.invoice[0].number }}</span>
            </b-col>
            <b-col
              class="d-flex flex-column align-items-start boleto-cel"
              style="border-left: 1px solid black"
            >
              <span class="boleto-cel-title"
                >Agência/Código do Beneficiário</span
              >
              <span class="boleto-cel-value"
                >{{ unityData.agency
                }}<template v-if="unityData.agency_d"
                  >-{{ unityData.agency_d }}</template
                ></span
              >
            </b-col>
          </b-row>
          <b-row style="border-bottom: 1px solid black">
            <b-col class="d-flex flex-column align-items-start boleto-cel">
              <span class="boleto-cel-title"
                >Instruções de responsabilidade do beneficiário. Qualquer dúvida
                sobre este boleto, contate o beneficiário.</span
              >
              <span class="boleto-cel-value"
                >SR. CAIXA, FAVOR NÃO COBRAR JUROS E RECEBER APÓS O
                VENCIMENTO</span
              >
              <br /><br />
            </b-col>
          </b-row>
          <b-row style="border-bottom: 1px solid black">
            <b-col class="d-flex flex-column align-items-start boleto-cel">
              <span class="boleto-cel-title" style="font-weight: 900"
                >Beneficiário</span
              >
              <span class="boleto-cel-value" style="font-weight: 900">{{
                data.unityBankAccount[0].beneficiary_name
              }}</span>
            </b-col>
          </b-row>
          <b-row style="border-bottom: 1px solid black">
            <b-col class="d-flex flex-column align-items-start boleto-cel">
              <span class="boleto-cel-title">Intermediado por:</span>
              <span class="boleto-cel-value">{{ unityData.company_name }}</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="d-flex flex-column align-items-start boleto-cel">
              <span class="boleto-cel-title">Pagador:</span>
              <span class="boleto-cel-value">{{
                data.financialClient.financial_name
              }}</span>
              <span class="boleto-cel-value"
                >{{ address.address }}, {{ address.number }} -
                {{ address.district }} - {{ address.city }} -
                {{ address.state }} - CEP: {{ address.zip_code }}</span
              >
              <span class="boleto-cel-title">Sacador/Avalista</span>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="3" style="border-left: 1px solid black">
          <b-row style="border-bottom: 1px solid black">
            <b-col class="d-flex flex-column align-items-start boleto-cel">
              <span class="boleto-cel-title">Vencimento</span>
              <span class="boleto-cel-value">{{ vencimentDay }}</span>
            </b-col>
          </b-row>
          <b-row style="border-bottom: 1px solid black">
            <b-col class="d-flex flex-column align-items-start boleto-cel">
              <span class="boleto-cel-title">(=)Valor do documento</span>
              <span class="boleto-cel-value" style="font-weight: 800">{{
                toMonetary(data.invoice[0].total)
              }}</span>
            </b-col>
          </b-row>
          <b-row style="border-bottom: 1px solid black">
            <b-col class="d-flex flex-column align-items-start boleto-cel">
              <span class="boleto-cel-title">(-)Desconto/Abatimento</span>
              <span class="boleto-cel-value" style="font-weight: 800"
                >&nbsp;</span
              >
            </b-col>
          </b-row>
          <b-row style="border-bottom: 1px solid black">
            <b-col class="d-flex flex-column align-items-start boleto-cel">
              <span class="boleto-cel-title">(+)Mora/Multa/Juros</span>
              <span class="boleto-cel-value" style="font-weight: 800"
                >&nbsp;</span
              >
            </b-col>
          </b-row>
          <b-row style="border-bottom: 1px solid black">
            <b-col class="d-flex flex-column align-items-start boleto-cel">
              <span class="boleto-cel-title">(+)Outros Acréscimos</span>
              <span class="boleto-cel-value" style="font-weight: 800"
                >&nbsp;</span
              >
            </b-col>
          </b-row>
          <b-row>
            <b-col class="d-flex flex-column align-items-start boleto-cel">
              <span class="boleto-cel-title">(=)Valor cobrado</span>
              <span class="boleto-cel-value" style="font-weight: 800"
                >&nbsp;</span
              >
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="8" class="p-0">
          <div class="m-2" v-html="barcode"></div>
        </b-col>
        <b-col class="d-flex flex-column align-items-end p-0">
          <span style="font-size: 12px; font-weight: 900"
            >FICHA DE COMPENSAÇÃO</span
          >
          <span style="font-size: 10px; font-weight: 700"
            >Autenticação Mecânica</span
          >
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  props: [ "data", "barcode", "digitableLine"],
  computed: {
    unityData(){
      return this.$store.state.checkout.unityData[0]
    },
    address() {
      let address = {};
      try {
        address = JSON.parse(this.data.financialClient.address);
      } catch (e) {
        address = this.data.financialClient.address;
      }
      return address;
    },
    vencimentDay() {
      return moment(this.data.invoice[0].venciment_day)
        .add("hours", "3")
        .format("DD/MM/YYYY");
    },
    documentDate() {
      return moment(this.data.invoice[0].created_at)
        .add("hours", "3")
        .format("DD/MM/YYYY");
    },
  },
};
</script>

<style lang="scss" scoped>
.boleto-cel-title {
  line-height: 1;
  font-size: 7px;
  font-weight: 700;
}
.boleto-cel-value {
  line-height: 1.5;
  font-size: 10px;
}
.boleto-cel {
  padding: 2px;
}
hr.dashed {
  border-top: 1px dashed black;
}
.bank-ticket {
  background-color: rgb(235, 235, 235);
  padding: 2px 10px;
}
.card-title-custom {
  font-size: 7px;
}
.card-body-custom {
  font-size: 10px;
}
</style>